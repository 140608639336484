.scroll-top {
  position: fixed;
  bottom: 70px;
  right: 15px;

  @media (max-width: 750px){
    bottom:130px;
  }

  &__button {
    appearance: none;
    border: none;
    outline: none;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: var(--main-accent-bg-color);
    color: #ffffff;
    text-shadow: 1px 1px 2px black;
    font-size: 20px;

    svg{
      width: 15px;
      height: 30px;
    }
  }
}