.faq {
  margin: 20px auto;

  &__item {
    margin: 25px 0;
    position: relative;
    background-color: #ffffff;
    border-radius: 10px;
    -webkit-box-shadow: 5px 5px 15px 4px rgba(163,163,163,0.29);
    box-shadow: 5px 5px 15px 4px rgba(163,163,163,0.29);
  }

  &__toggle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  &__question {
    margin: 0;
    padding: 10px 10px 10px 25px;
    cursor: pointer;
    position: relative;

    @media (max-width: 750px){
      font-size: 15px;
    }

    &:before{
      content: ' + ';
      position: absolute;
      left: 6px;
      top: 6px;
      font-size: 24px;
      color: var(--content-button-bg-color);
    }
  }
  .hideQuestion{
    &:before{
      content: ' - ';
      position: absolute;
      font-size: 24px;
      left: 6px;
      top: 6px;
      color: var(--content-button-bg-color);
    }
  }

  &__answer {
    height: auto;
    max-height: 0;
    overflow: hidden;
    margin: 0;
    background-color: #8080800d;
    border-radius: 0 0 10px 10px;
    font-weight: 400;

    &.active {
      max-height: 1000px;
      padding: 1rem;
    }
  }
}
