.mobileButtonContainer{
  display: flex;
  justify-content: center;
}

.mobileButton{
  max-width: 100%;
  margin: 0 10px;
  position: fixed;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: gray;
  border-radius: 5px;

  &__description{
    text-align: center;
    padding: 0 10px;
  }

  &__link{
    text-align: center;
  }

  img{
    max-width: 80px;
  }

  a,
  button{
    margin: 10px;
    display: block;
    padding: 10px;
    background: #fff;
    color: var(--main-nav-color);
    text-decoration: none;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 100;
    border: none;
  }
}