.page-footer {
  background-color: var(--header-bg-color);

  h2,
  h3,
  h4,
  h5,
  h6{
    font-size: 20px;
    margin-bottom: 5px;
  }
  ul{
    list-style: none;
    margin: 0;
    padding: 0;
    li{
      a{
        font-size: 16px;
        display: block;
        margin-bottom: 10px;
        font-weight: 400;
        text-decoration: none;
      }
    }
  }

  &__logo {
    @media (max-width: 550px) {
      text-align: center;
    }
  }


  &__nav {

  }

  &__list {
    margin-top: 0;

    a {
      font-size: 1.1rem;
      font-weight: 300;
      color: var(--main-nav-color);
      text-decoration: none;

      &:hover {
        color: var(--main-accent-bg-color);
      }
    }

    .sub-menu {
      margin: 0.2rem 0;
    }
  }

  &__text {
    color: var(--main-nav-color);
    font-weight: 300;
    font-size: 0.9rem;
    line-height: 1.4rem;
  }

  & .columns__item {
    width: 30%;
    & > * {
    }

    * {
      color: var(--main-nav-color);
    }
  }
}
