.toc-auto{
  max-width: 500px;
  background: #f3f4f4;
  margin-bottom: 30px;
  padding: 17px 30px;
  border-radius: 5px;
  box-shadow: 1px 3px 5px 1px rgba(0,0,0,0.1);

@media (max-width: 750px){
    margin-top: 20px;
  }

  // style for list style like 1.1
  ul { counter-reset: item }
  li{ display: block }
  li:before {
    content: counters(item, ".") " ";
    counter-increment: item;
    font-weight: 100;
    color: gray;
  }

  ul{
    display: none;
  }

  p{
    margin: 0;
    padding: 0;
  }
  h2{
    font-size: 20px;
    text-transform: none;
    margin: 0 20px 0 0;
    padding: 0;
  }
  button{
    background: none;
  }

  .toc-hide-block{
    display: block;
    position: relative;
    width: 100%;
    font-weight: bold;
    font-size: 18px;
    text-align: left;
    border: none;

    @media (max-width: 750px) {
      width: 100%;
    }

    &:hover{
      cursor: pointer;
    }
  /*
    &:after{
      content: "[ show ]";
      position: absolute;
      right: -40px;
      top: 2px;
      font-size: 17px;
      font-weight: bold;

      @media (max-width: 750px) {
        right: -20px;
      }
    }

   */
  }
  a{
    text-decoration: none;
    color: var(--content-button-bg-color);
    font-weight: 100;
    &:hover{
      color: #333333;
    }
  }

  ul{
    margin: 0 0 0 10px;
    padding: 0;
  }
  li{
    margin: 10px 0;
    padding: 0;
  }
}
.tocOpen{
   ul{
    margin-top: 20px;
    display: block;
    transition: all .5s;
     li{
       ul{
         margin-top: 0;
       }
     }
  }
  .toc-hide-block{
    /*
    &:after{
      content: "[ hide ]";
      position: absolute;
      right: -40px;
      top: 2px;
      font-size: 17px;
      font-weight: bold;

      @media (max-width: 750px) {
        right: -20px;
      }
    }

     */
  }
}

.autoToc__navigation{
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-start;
}