.page-header {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  margin-bottom: 30px;


  p{
    padding: 0;
    margin: 0;
  }

  &__container {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    transition: all .3s;
    z-index: 100;

    @media (max-width: 1024px) {
      justify-content: space-between;
      padding: var(--offsets) 0;
      position: relative;
    }

    @media (max-width: 750px){
      background-color: var(--header-bg-color);
    }
  }

  .backgroundGrayContainer{
    background-image: linear-gradient(180deg,rgba(0,0,0,0.49) 0%,rgba(0,0,0,0.43) 100%);
    background-color:rgba(255,255,255,0) ;

    @media (max-width: 9500px){
      height: 100vh;
    }
  }


  .changeBackground{
    background-color: #000000ab;
    transition: all .3s;

    @media (max-width: 750px)
    {
      background-color: transparent;
    }

  }
  .pageHeaderPromo{
    padding: 15% 0 0 0 ;
    height: 100vh;
    text-align: center;
    color: #ffffff;
    font-weight: bold;

    @media (max-width: 750px){
      height: 76.5vh;
      padding: 0 20px 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__title{
      font-size: 38px;
      display: block;
      margin-bottom: 20px;

      @media (max-width: 750px){
        font-size: 22px;
        margin-top: 10px;
      }

    }
    &__description{
      font-size: 28px;
      max-width: 1200px;
      margin: 0 auto;
      line-height: 36px;

      @media (max-width: 750px){
        font-size: 18px;
        line-height: 19px;
      }

    }
    .pageHeaderPromoButtons{
      display: flex;
      justify-content: center;
      margin-top: 20px;

      .secondButton{
          background-color: var(--content-button-bg-color);
      }


      a,
      span,
      button{
        padding: 14px 30px;
        border-radius: 3px;
        margin: 15px;
        text-decoration: none;
        color: #ffffff;
        background-color: #00000073;
        font-weight: 700;
        border: 3px solid #ffffff;

        @media (max-width: 740px){
          padding: 14px 10px;
        }


        &:hover{
          border: 3px solid #00000000;
          background-color: #ffffff24;
          transition: all .5s;
        }
      }
    }
  }

  &__logo {
    max-width: 150px;
    width: 100%;
    margin-left: 10px;

    @media (max-width: 750px){
      img{
        max-height: 20px;
      }
    }

    @media (max-width: 1024px) {
      max-width: 100px;
    }
  }

  &__nav {
    margin: 0 auto;

    @media (max-width: 1024px) {
      order: 3;
    }
  }

  &__buttons {

    a{
      font-weight: 400;
    }

    @media (max-width: 1024px) {
      order: 2;
    }
    @media (max-width: 550px) {
      margin: 0 -0.2rem;
    }
    @media (max-width: 500px) {
      width: 100%;
      order: 5;
      text-align: center;
      margin-top: 1rem;
    }

    button,
    a{
      margin: 1rem;
      @media (max-width: 550px) {
        margin: 0 0.2rem;
      }
    }
  }

  &__burger {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 35px;
    height: 25px;

    @media (max-width: 1024px) {
      display: flex;
      order: 3;
    }

    span {
      display: block;
      height: 3px;
      width: 100%;
      background-color: var(--main-accent-bg-color);
      border-radius: 20%;
      transform-origin: center;
      transition: 250ms all;
    }

    &.active {
      span {
        &:nth-of-type(1) {
          transform: translateY(11px) rotateZ(45deg);
        }
        &:nth-of-type(2) {
          transform: scaleX(0);
        }
        &:nth-of-type(3) {
          transform: translateY(-11px) rotateZ(-45deg);
        }
      }
    }
  }
}

.page-header-style-2{
  height: auto;
  margin-bottom: 94px;

  @media ( max-width: 750px ){
    margin-bottom: 0;
  }

  .page-header__container{
    background-color: var(--header-bg-color);
  };
}

.disable_sticky_menu{
  margin-bottom: 0;
  .page-header__container{
    position: relative;
  }
}


