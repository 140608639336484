
.sidenav {
  position: fixed;
  z-index: 1;
  top: 99px;
  right: -200vh;
  background-color: var(--header-bg-color);
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 0;

  li.menu-item-has-children:after{
    content:"";
    mask:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' width='25' height='25' x='0' y='0' viewBox='0 0 512.002 512.002' style='enable-background:new 0 0 512 512' xml:space='preserve' class=''%3E%3Cg transform='matrix(6.123233995736766e-17,1,-1,6.123233995736766e-17,512.0016722679138,0.0004363059997558594)'%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath d='M388.425,241.951L151.609,5.79c-7.759-7.733-20.321-7.72-28.067,0.04c-7.74,7.759-7.72,20.328,0.04,28.067l222.72,222.105 L123.574,478.106c-7.759,7.74-7.779,20.301-0.04,28.061c3.883,3.89,8.97,5.835,14.057,5.835c5.074,0,10.141-1.932,14.017-5.795 l236.817-236.155c3.737-3.718,5.834-8.778,5.834-14.05S392.156,245.676,388.425,241.951z' data-original='%23000000' style='' class=''%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat 50% 50%;;
    position: absolute;
    opacity: .7;
    right: 25px;
    top: -4px;
    width: 20px;
    height: 20px;
    transition: all .5s;
    background-color: var(--main-nav-color);
  }

  ul{
    margin: 0;
    padding: 0;
    list-style: none;
    li{
      position: relative;
      margin: 0;
      padding: 0;
      ul{
        display: none;
        padding: 0;
      }
      a{
        display: block;
        text-decoration: none;
        margin: 16px 0;
        color: var(--main-nav-color);
        font-size: 19px;
        font-weight: 100;
      }
    }
  }

}

.menu-header-mobile{
  ul{
    li.active {
      &:after{
        transform: rotateZ(180deg);
        transition: all .5s;
        position: absolute;
        right: 12px;
        top: 0;
        width: 20px;
        height: 20px;
      }
      ul{
        display: block;
      }
      ul.sub-menu{
        margin-left: 10px;
      }
    }
  }
}

.menuFullWidth{
  right: 0;
  width: 100%;
}
.withoutButtons{
  top: 42px;
}

// animate burger menu
#openButton{
  width: 44px;
  height: 45px;
  position: absolute;
  right: 10px;
  top: 10px;
  margin-left: 100px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}


#openButton span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: var(--main-nav-color);
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}


#openButton {
}

#openButton span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#openButton span:nth-child(2) {
  top: 12px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#openButton span:nth-child(3) {
  top: 25px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#openButton.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -1px;
  left: 8px;
}

#openButton.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#openButton.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 32px;
  left: 8px;
}