.accent-button {
    display: inline-block;
    text-decoration: none;
    border: none;
    outline: 0;
    background: 0 0;
    padding: 9px 19px;
    font-size: 18px;
    font-weight: 300;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    transition: 250ms;

  &.fill-button {
    background: var(--main-accent-bg-color);
    border: 2px solid var(--main-accent-bg-color);

    &:hover {
      filter: brightness(105%);
    }
  }

  &.outline-button {
    color: rgba(#ffffff, 0.5);
    background-color: transparent;
    border: 2px solid rgba(#ffffff, 0.5);

    &:hover {
      background-color: rgba(#ffffff, 0.1);
    }
  }
}

.button-content {
  margin-top: 2rem;

  &.alight-left {
    text-align: left;
  }
  &.align-right {
    text-align: right;
  }
  &.align-center {
    text-align: center;
  }

  & button,
  & a{
    background: var(--content-button-bg-color);
    display: inline-block;
    border: 2px solid var(--content-button-bg-color);
    padding: 0.7rem 2rem;
    font-size: 1.2rem;
    font-weight: 100;
    border-radius: 5px;
    color: var(--content-button-color);
    transition: 150ms all;
    transform-origin: center;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      transform: scale(0.95);
    }
  }
}
