.header-menu{
  margin: 0 auto 0 auto;
  z-index: 100;
  .menu{
    display: flex;
    list-style: none;
    margin-top: 0;
    padding-left: 0;
    a{
      text-decoration: none;
      color: #ffffff;
      font-weight: 400;
      font-size: 19px;
      margin-right: 25px;
      text-transform: uppercase;

      &:hover{
        color: var(--main-nav-color);
        transition: all .5s;
      }
    }
    li{
      margin-right: 10px;
      position: relative;
    }
    li.current-menu-item{
      a{
        color: var(--main-nav-color);
        font-weight: bold;
      }
    }
  }
}

#menu-header-menu li ul {
  display:none;
  position: absolute;
  left: 0;
  text-align: left;
  z-index: 300;
  margin: 0;
  padding: 10px;
  border-radius: 5px;
  list-style: none;
  background-image: linear-gradient(180deg,rgba(0,0,0,.49) 0,rgba(0,0,0,.43) 100%);
  background-color: var(--header-bg-color);
  li{
    a{
      text-decoration: none;
      border-bottom: none;
      text-align: center;
      font-size: 16px;
      display: block;
      padding-bottom: 8px;
      padding-left: 0;
      text-transform: none;
    }
  }
}
#menu-header-menu li:hover > ul {
  display:block;
}
#menu-header-menu li ul ul {
  left:140px;
  top:10px;
  list-style: none;
}
#menu-header-menu li li a {
  border:0;
  width: 100%;
  border-bottom: 1px solid #bcbcbc;
  color: #fff;
  text-align: left;
  padding-left: 10px;
  text-decoration: none;
}


ul#menu-header-menu > .menu-item-has-children > a::after {
  content: "▼";
  top: 0;
  position: absolute;
  font-size: 13px;
  padding-left: 3px;
}